import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import {
  HTMLAttributes,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'

import { DatoStructuredText } from '@/features/common-blocks'
import { useElementRect } from '@/hooks/useElementRect'
import { absoluteFill, animateIn } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import FormCheckbox from './FormCheckbox'
import FormCheckboxArray from './FormCheckboxArray'
import FormSelectField from './FormSelectField'
import FormTextArea from './FormTextArea'
import FormTextField from './FormTextField'
import LoadingSpinner from './LoadingSpinner'

export type ConditionsJSON = {
  fields: [
    {
      label: string
      showIf: {
        label: string
        equalsAny: string[]
      }
    },
  ]
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  data?: Queries.FormFragment | null
}

const Form = ({ data, ...props }: Props): JSX.Element => {
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null)
  const [successRef, setSuccessRef] = useState<HTMLElement | null>(null)

  const { height: formHeight } = useElementRect(formRef)
  const { height: successHeight } = useElementRect(successRef)

  const formData = useRef<{ [key: string]: string }>({})

  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleChange = useCallback((name: string, value: string) => {
    formData.current[name] = value
  }, [])

  const [conditionData, setConditionData] = useState<{
    [name: string]: string
  }>({})

  const conditionalJSON = useMemo(() => {
    return (
      data?.conditionalFields &&
      (JSON.parse(
        data.conditionalFields as unknown as string
      ) as ConditionsJSON)
    )
  }, [data?.conditionalFields])

  const conditionalFields = conditionalJSON?.fields

  const handleSubmit = () => {
    const submitFunction = async (data: {
      url: string
      method: string
      headers?: { [key: string]: string }
      body: string
    }) => {
      setSubmitting(true)
      try {
        const response = await fetch(data.url, {
          method: data.method,
          headers: data.headers,
          body: data.body,
        })
        if (response) {
          setSubmitting(false)
        }
        if (response.ok) {
          setSubmitted(true)
        } else {
          alert(
            `Sorry, there was an error submitting this form: ${response.status} ${response.statusText}`
          )
        }
      } catch (error) {
        alert(
          `Sorry, there was an error submitting this form: ${error}`
        )
      }
    }

    const encode = (data: { [key: string]: string | null }) => {
      return Object.keys(data)
        .map(key => {
          if (data[key] !== null) {
            return (
              encodeURIComponent(key) +
              '=' +
              encodeURIComponent(data[key] as string)
            )
          }
        })
        .join('&')
    }

    submitFunction({
      url: '/',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encode({
        'form-name': data?.formName || null,
        ...formData.current,
      }),
    })
  }

  const styles = {
    wrapper: css`
      position: relative;
      display: grid;
      overflow: hidden;
      width: auto;
      height: auto;
      height: ${submitting && formHeight + 'px'};
      height: ${submitted && successHeight + 'px'};
      transition: all 300ms ease;
    `,
    form: css`
      grid-area: 1 / 1 / 2 / 2;
      align-self: flex-start;
      display: flex;
      flex-wrap: wrap;
      --gap: 1em;
      gap: var(--gap);
      align-items: flex-start;
      justify-content: flex-start;
      opacity: 1;
      transition:
        opacity 200ms ease-out,
        transform 300ms ease-out;
      > input[type='hidden'] {
        display: none;
      }
      ${(submitting || submitted) &&
      css`
        opacity: 0;
        transform: translate3d(0, -6rem, 0);
      `}
    `,
    buttonWrap: css`
      display: flex;
      flex-basis: 100%;
      margin-top: 0.5em;
    `,
    button: css`
      align-self: stretch;
      font-size: 125%;
      padding: 0.5em 0.75em;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.05em;
      display: flex;
      align-items: center;
      position: relative;
      grid-column: 1 / -1;
      justify-self: flex-start;
      box-sizing: border-box;
      border-radius: 0.5em;
      transition: all 200ms ease;
      background: ${colors.blue};
      color: #fff;
      @media (hover: hover) {
        &:hover {
          background-color: ${colors.rose};
        }
      }
      form:invalid & {
        background: ${colors.gray75};
        color: #ffffffcc;
      }
      form:not(:invalid) & {
        @media (hover: hover) {
          &:hover,
          &:focus-within {
          }
        }
      }
      span {
        position: relative;
      }
      input {
        ${absoluteFill}
        appearance: none;
        border: none;
        background: transparent;
        z-index: 2;
        border: 0;
        padding: 0;
        cursor: pointer;
      }
    `,
    successMessage: css`
      grid-area: 1 / 1 / 2 / 2;
      opacity: 0;
      transform: translate3d(0, 6rem, 0);
      animation: ${animateIn} 300ms ease-out forwards;
      align-self: flex-start;
      color: ${colors.gray30};
      border-top: 1px solid ${colors.gray75};
      padding-top: 1em;
      h2 {
        font-size: var(--fs-36);
        color: ${colors.blue};
        margin: 0.5em 0;
      }
    `,
    spinner: css`
      grid-area: 1 / 1 / 2 / 2;
      aspect-ratio: 1 / 1;
      height: 4em;
      width: auto;
      max-height: 75%;
      align-self: center;
      justify-self: center;
      visibility: hidden;
      opacity: 0;
      transition: opacity 500ms ease;
      ${submitting &&
      css`
        visibility: visible;
        opacity: 1;
      `}
    `,
  }

  return (
    <div
      css={styles.wrapper}
      {...props}
    >
      <LoadingSpinner
        css={styles.spinner}
        color={colors.rose}
      />
      {submitted ? (
        <div
          ref={node => setSuccessRef(node)}
          css={[styles.successMessage]}
        >
          <DatoStructuredText data={data?.successMessage} />
        </div>
      ) : (
        <form
          css={styles.form}
          ref={node => setFormRef(node)}
          name={data?.formName || undefined}
          data-netlify
          netlify-honeypot={'bot-field'}
          method="post"
          onSubmit={(e: SyntheticEvent) => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <input
            type="hidden"
            name="bot-field"
            aria-hidden
            tabIndex={-1}
          />
          <input
            type="hidden"
            name="form-name"
            value={data?.formName || undefined}
            aria-hidden
            tabIndex={-1}
          />

          {data?.formFields?.map(field => {
            const checkIfHidden = () => {
              const condition = conditionalFields?.find(
                conditionalField =>
                  field?.label === conditionalField.label
              )
              if (condition) {
                const testCondition = condition.showIf.equalsAny.some(
                  value =>
                    conditionData[condition.showIf.label] === value
                )
                return !testCondition
              }
              return false
            }
            const isHidden = checkIfHidden()
            switch (field?.__typename) {
              case 'DatoCmsFormTextField':
                return (
                  <FormTextField
                    data={field}
                    onChange={handleChange}
                    key={field.id}
                    isHidden={isHidden}
                  />
                )
              case 'DatoCmsFormTextArea':
                return (
                  <FormTextArea
                    data={field}
                    onChange={handleChange}
                    key={field.id}
                    isHidden={isHidden}
                  />
                )
              case 'DatoCmsFormSelectField':
                return (
                  <FormSelectField
                    data={field}
                    onChange={handleChange}
                    conditionalFields={conditionalFields}
                    setConditionData={setConditionData}
                    key={field.id}
                    isHidden={isHidden}
                  />
                )
              case 'DatoCmsFormCheckboxArray':
                return (
                  <FormCheckboxArray
                    data={field}
                    onChange={handleChange}
                    key={field.id}
                    isHidden={isHidden}
                  />
                )
              case 'DatoCmsFormCheckbox':
                return (
                  <FormCheckbox
                    data={field}
                    onChange={handleChange}
                    key={field.id}
                    isHidden={isHidden}
                  />
                )
            }
          })}
          <div css={styles.buttonWrap}>
            <div css={styles.button}>
              <span>{data?.submitButtonText}</span>
              <input
                name="submit"
                type="submit"
                aria-label={data?.submitButtonText || 'Submit'}
                value=""
              />
            </div>
          </div>
        </form>
      )}
    </div>
  )
}

export const FormFragment = graphql`
  fragment Form on DatoCmsForm {
    __typename
    id: originalId
    formName
    formFields {
      __typename
      ... on DatoCmsFormTextField {
        ...FormTextField
      }
      ... on DatoCmsFormSelectField {
        ...FormSelectField
      }
      ... on DatoCmsFormTextArea {
        ...FormTextArea
      }
      ... on DatoCmsFormCheckboxArray {
        ...FormCheckboxArray
      }
      ... on DatoCmsFormCheckbox {
        ...FormCheckbox
      }
    }
    submitButtonText
    successMessage {
      value
    }
    conditionalFields
  }
`

export default Form
